.bio {
  display: block;
  margin-top: 2%; 
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: auto;
  border-radius: 17%/20%; 
}
.site-txt-shop{
  margin-top: 2%; 
  margin-bottom: 2%; 
  margin-left: 18%; 
  margin-right: 18%; 
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 15vh/50vh; 
  background-color: #b8f2e6;
  color: #282c34;
}
a:link {
  color: #282c34;
}
.site-txt-contact{
  margin-top: 2%; 
  margin-bottom: 2%; 
  margin-left: 18%; 
  margin-right: 18%; 
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 15vh/50vh; 
  background-color: #ffa69e;
  color: #282c34;
}
.site-txt-contact-email:link{
  color: #282c34;
  width: 100%; 
  text-align: center;
}

.site-txt-contact-email{
  margin-top: 2%; 
  margin-bottom: 2%; 
  margin-left: 18%; 
  margin-right: 18%; 
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 15vh/50vh; 
  background-color: #b8f2e6;
  color: #282c34;
}

.site-txt-portfolio{
  margin-top: 2%; 
  margin-bottom: 2%; 
  margin-left: 18%; 
  margin-right: 18%; 
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 15vh/50vh; 
  background-color: #ffa69e;
  color: #282c34;
}

.site-txt-home {
  margin-top: 2%; 
  margin-bottom: 2%; 
  margin-left: 18%; 
  margin-right: 18%; 
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 15vh/50vh; 
  background-color: #ffa69e;
  color: #282c34;
}

.site-txt-about{
  margin-top: 2%; 
  margin-bottom: 2%; 
  margin-left: 2%; 
  margin-right: 2%; 
  text-align: center;
  background-color:#61dafb;
  padding: 0.5rem 0.75rem;
  border-radius: 15vh/50vh; 
  background-color: #ffa69e;
  color: #282c34;
  padding-left: 3vh;
  padding-right: 3vh;
}

.tab-list {
  display: block; 
  margin-left: auto;
  margin-right: auto;
  text-align: center; 
}

.tab-list-item {
  border-radius: 12%/27%; 
  margin: 5px;
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0.75rem;
  font-size: calc(20px + 2vmin);
  background-color: #282c34;
  color: white
}

.tab-list-active {
  border-color: #ffa69e;
  border-style: solid;
  border-width: .5vh;
}

.App {
  text-align: center;
  background-color: #faf3dd;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  display: block; 
  margin-left: auto;
  margin-right: auto;
  
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

*,*::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: calibri;
}

body {
    /* display: flex */
    justify-content: center;
    background-color: #faf3dd;
}

swiper-container {
}

swiper-slide {
   width: 100%;
   height: 100%;
   width: fit-content;
   height: fit-content;
}

swiper-slide img {
    width: 100%;
    object-fit: cover;
}
